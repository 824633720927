import { useAppDispatch, useAppSelector } from 'hooks'
import { ReactElement, useEffect } from 'react'
import { setTcoOpen, setTptOpen } from 'reducers/interfaces/slices'
import { setBroadcast } from 'reducers/simulations/slices'
import { setSnciOpen } from 'reducers/snci/slices'
import { isLogsWindow } from 'services/route'
import { BroadcastMessage } from 'types/interfaces'

export default function BroadcastStream(): ReactElement {
  const dispatch = useAppDispatch()
  const { selectedSimulation } = useAppSelector(state => state.simulations)
  let bc

  useEffect(() => {
    if (selectedSimulation) {
      bc = (new BroadcastChannel(selectedSimulation.id))

      // todo refacto
      bc.onmessage = (event: MessageEvent) => {
        if (event.data.type === BroadcastMessage.closeTco) {
          dispatch(setTcoOpen(false))
          return
        }
        if (event.data.type === BroadcastMessage.closeTpt) {
          dispatch(setTptOpen(false))
        }
        if (event.data.type === BroadcastMessage.closeSnci) {
          dispatch(setSnciOpen(false))
        }
        if (event.data.type === BroadcastMessage.openTco) {
          dispatch(setTcoOpen(true))
        }
        if (event.data.type === BroadcastMessage.openTpt) {
          dispatch(setTptOpen(true))
        }
        if (event.data.type === BroadcastMessage.openSnci) {
          dispatch(setSnciOpen(true))
        }
      }

      dispatch(setBroadcast({ current: bc }))

      window.addEventListener('beforeunload', () => {
        bc.postMessage({ type: BroadcastMessage.closeTco })
        bc.postMessage({ type: BroadcastMessage.closeTpt })
        bc.postMessage({ type: BroadcastMessage.closeSnci })
      })
    }

    return () => {
      if (bc && !isLogsWindow()) {
        bc.postMessage({ type: BroadcastMessage.closeTco })
        bc.postMessage({ type: BroadcastMessage.closeTpt })
        bc.postMessage({ type: BroadcastMessage.closeSnci })
        dispatch(setBroadcast(undefined))
      }
    }
  }, [selectedSimulation])

  return (
    <> </>
  )
}
