import { get, post } from '@osrdata/app_core/dist/requests'
import { createApiThunk } from 'utils'

import { setDashboard, setSimulation } from './slices'

const createDashboard = (scheduleId: string) => createApiThunk(
  'trainer/createDashboard',
  () => post('/masters/dashboards', { name: Math.random().toString(36).substring(2, 8), scheduleId }),
  setDashboard,
)

const getDashboard = (id: string) => createApiThunk(
  'trainer/getDashboard',
  () => get(`/masters/dashboards/${id}`),
  setDashboard,
)

const createDashboardSimulation = (code: string) => createApiThunk(
  'trainer/getDashboard',
  () => post(
    '/masters/simulations-dashboard/',
    { name: Math.random().toString(36).substring(2, 8), dashboardCode: code },
  ),
  setSimulation,
)

export {
  createDashboard, createDashboardSimulation, getDashboard,
}
