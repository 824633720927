import React, { MouseEvent, ReactElement, useEffect } from 'react'
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch'
import './style.scss'
import { useAppDispatch, useAppSelector } from 'hooks'
import { RootState } from 'reducers/store'
import { setForm } from 'reducers/interfaces/slices'
import { isDevEnv } from 'utils'

type Props = {
  svg: string;
  handleClick?: (evt: MouseEvent<HTMLElement>) => void;
  coords: {x: number, y: number};
}

export default function SvgWrapper({ svg, handleClick, coords }: Props): ReactElement {
  const dispatch = useAppDispatch()
  const { form } = useAppSelector((state: RootState) => state.interfaces)
  const { webSocket } = useAppSelector((state: RootState) => state.simulations)

  // todo remove poc
  const hoverMargin = 10
  let animationFrameId = null

  const checkHoverZones = event => {
    const rectangles = document.querySelectorAll('[id^="ZoneLight"]')

    rectangles.forEach((rect: SVGElement) => {
      const rectBounds = rect.getBoundingClientRect()

      const inExtendedHoverZone = (
        event.clientX >= rectBounds.left
              && event.clientX <= rectBounds.right
              && event.clientY >= rectBounds.top - hoverMargin
              && event.clientY <= rectBounds.bottom + hoverMargin
      )

      if (inExtendedHoverZone) {
        rect.style.stroke = rect.getAttribute('fill')
        rect.style.strokeWidth = '10'
      } else {
        rect.style.strokeWidth = '0'
      }
    })
  }

  useEffect(() => {
    if (!svg) return

    // tmp fix
    const elementsWithFontFamily = document.querySelectorAll('[font-family]')

    const styleSheet = document.createElement('style')
    document.head.appendChild(styleSheet)
    elementsWithFontFamily.forEach((element, index) => {
      if (element.classList.contains('custom-font-family')) return
      const fontFamily = element.getAttribute('font-family')
      const className = `custom-font-family-${index}`
      element.classList.add(className)

      styleSheet.sheet.insertRule(
        `.${className} { font-family: ${fontFamily} !important }`,
        styleSheet.sheet.cssRules.length,
      )
    })

    if (isDevEnv()) {
      document.addEventListener('mousemove', event => {
        if (!animationFrameId) {
          animationFrameId = requestAnimationFrame(() => {
            checkHoverZones(event)
            animationFrameId = null
          })
        }
      })
    }
  }, [svg])

  const sendMessage = (message: string, id: string) => {
    webSocket?.current?.send(JSON.stringify({
      messageType: 'DISTURBANCE',
      disturbanceType: message,
      disturbanceId: id,
    }))
    dispatch(setForm(undefined))
  }
  return (
    <div className="interface-wrapper">
      {
        !!(coords.y && coords.x && form) && (
          <div
            style={{
              position: 'absolute',
              top: coords.y,
              left: coords.x,
              width: '200px',
              background: '#D9D9D9',
              borderRadius: 3,
              zIndex: 3,
              padding: '8px',
            }}
            className="form"
          >

            {form?.map(f => (
              <div key={crypto.randomUUID()}>
                <div style={{ marginTop: '12px', marginLeft: '8px' }}>
                  {`${f.automatonVerboseName} ${f.automatonName}`}
                </div>

                {f.actions.map(action => (

                  action.available && (
                  <div
                    className="action-button"
                    style={{ paddingLeft: '18px', marginTop: '4px', cursor: 'pointer' }}
                    key={crypto.randomUUID()}
                    onClick={() => sendMessage(action.disturbanceType, action.disturbanceId)}
                  >
                    {action.disturbanceType.includes('ADD') ? 'Ajouter : ' : 'Retirer : '}
                    {' '}
                    {action.disturbanceName}
                  </div>
                  )
                ))}

              </div>

            ))}
          </div>
        )
      }

      <TransformWrapper
        wheel={{ smoothStep: 0.01 }}
      >
        <TransformComponent
          wrapperStyle={{ height: '100%', width: '100%' }}
          contentStyle={{ height: '100%', width: '100%' }}
        >
          <div
            onClick={handleClick}
            style={{
              width: '100%',
              height: '100%',
            }}
          // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: svg }}
          />
        </TransformComponent>
      </TransformWrapper>

    </div>
  )
}

SvgWrapper.defaultProps = {
  handleClick: () => undefined,
}
