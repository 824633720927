import { post } from '@osrdata/app_core/dist/requests'
import { createApiThunk } from 'utils'
import { setSimulation } from './slices'

const createSimulationFromDashboard = (code: string) => createApiThunk(
  'student/getDashboard',
  () => {
    const name = Math.random().toString(36).substring(2, 6)
    return post(
      '/masters/simulations-dashboard/',
      { name, dashboardCode: code },
    )
  },
  setSimulation,
)

export {
  createSimulationFromDashboard,
}
