import { Box, TextField } from '@mui/material'
import moment from 'moment'
import { ReactElement, useState } from 'react'
import { fr } from 'date-fns/locale'
import { Calendar } from 'react-date-range'
import './DateInput.scss'

type Props = {
  defaultValue: string;
  onChange: (newValue: string) => void;
}
export default function DateInput({ defaultValue, onChange }: Props): ReactElement {
  const [open, setOpen] = useState(false)

  return (
    <>
      <TextField
        className="date-picker"
        onClick={() => setOpen(true)}
        InputProps={{
          readOnly: true,
        }}
        variant="outlined"
        fullWidth
        value={defaultValue ? moment(defaultValue).format('DD/MM/yyyy') : ''}
      />
      {open && (
      <Box
        className="calendar-wrapper"
      >
        <Calendar
          locale={fr}
          onChange={item => {
            onChange(moment(item).format('yyyy-MM-DD'))
            setOpen(false)
          }}
          date={defaultValue ? new Date(defaultValue) : null}
        />
      </Box>
      )}
    </>
  )
}
