import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import {
  LongBeep, ShortBeep, SimulationsState, SvgStream,
} from 'reducers/types'
import { isLongBeep, isShortBeep } from 'services/snci'

export type SnciState = {
  isOpen: boolean;
  stream: SvgStream;
  simulationState: SimulationsState;
  html: string;
  beep: ShortBeep | LongBeep;
}

const initialState: SnciState = {
  isOpen: false,
  stream: undefined,
  simulationState: SimulationsState.stopped,
  html: '',
  beep: undefined,
}

const snciSlice = createSlice({
  name: 'snci',
  initialState,
  reducers: {
    setOpen: (state, action: PayloadAction<boolean>) => {
      state.isOpen = action.payload
    },
    updateStream: (state, action: PayloadAction<SvgStream>) => {
      state.stream = action.payload
    },
    setHtml: (state, action: PayloadAction<string>) => {
      state.html = action.payload
    },
    setBeep: (state, action: PayloadAction<ShortBeep | LongBeep>) => {
      if (!state.beep || isShortBeep(state.beep)) {
        state.beep = action.payload
        return
      }

      if (isLongBeep(state.beep)) {
        if (!state.beep.state || isLongBeep(action.payload)) {
          state.beep = action.payload
        }
      }
    },
  },
})

export const {
  setOpen: setSnciOpen,
  updateStream: updateSnciStream,
  setHtml: setSnciHtml,
  setBeep,
} = snciSlice.actions

export default snciSlice.reducer
