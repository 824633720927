import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { SimulationsState, SvgStream } from 'reducers/types'
import { InteractionForm } from 'types/websocket'

export type InterfacesState = {
  stream: SvgStream;
  simulationState: SimulationsState;
  svg: string;
  isTcoOpen: boolean;
  isTptOpen: boolean;
  form: InteractionForm[];
}

const initialState: InterfacesState = {
  stream: undefined,
  simulationState: SimulationsState.stopped,
  svg: '',
  isTcoOpen: false,
  isTptOpen: false,
  form: undefined,
}

const interfacesSlice = createSlice({
  name: 'interfaces',
  initialState,
  reducers: {
    setTcoOpen: (state, action: PayloadAction<boolean>) => {
      state.isTcoOpen = action.payload
    },
    setTptOpen: (state, action: PayloadAction<boolean>) => {
      state.isTptOpen = action.payload
    },
    updateStream: (state, action: PayloadAction<SvgStream>) => {
      state.stream = action.payload
    },
    setSvg: (state, action: PayloadAction<string>) => {
      state.svg = action.payload
    },
    updateSimulationState: (state, action: PayloadAction<SimulationsState>) => {
      state.simulationState = action.payload
    },
    setForm: (state, action: PayloadAction<InteractionForm[]>) => {
      state.form = action.payload
    },
  },
})

export const {
  setTcoOpen,
  setTptOpen,
  updateStream,
  setSvg,
  updateSimulationState,
  setForm,
} = interfacesSlice.actions

export default interfacesSlice.reducer
