import { ReactComponent as BackwardIcon } from 'assets/icons/backward.svg'
import { ReactComponent as ForwardIcon } from 'assets/icons/forward.svg'
import moment from 'moment'
import {
  useEffect, useRef, useState,
} from 'react'
import { useAppSelector } from 'utils'
import './style.scss'

// todo refacto
export default function TimeTools() {
  const progressRef = useRef<HTMLDivElement>(null)
  const draggingRef = useRef<boolean>(false)
  const {
    clockTime, webSocket, selectedSimulation, finalTime,
  } = useAppSelector(state => state.simulations)
  const [progressWidth, setProgressWidth] = useState<number>(0)

  const endTime = new Date(finalTime).getTime() > new Date(clockTime).getTime() ? finalTime : clockTime

  /**
   * Send the new timestamp to the backend
   *
   * @param progress - progress width in percentage
   */
  const handleSendTimestamp = (progress: number) => {
    webSocket?.current?.send(JSON.stringify({
      timestamp: clockTime,
      messageType: progress > 0 ? 'ADVANCE' : 'REWIND',
      amount: '00:00:15',
    }))
  }

  useEffect(() => {
    if (clockTime === endTime) {
      setProgressWidth(100)
      return
    }
    const time = (new Date(clockTime).getTime()
    - new Date(selectedSimulation?.initialTime).getTime())
    / (new Date(endTime).getTime() - new Date(selectedSimulation?.initialTime).getTime())
    setProgressWidth(time * 100)
  }, [clockTime])

  /**
   * Update the progress width based on the click or drag position
   * Then notify the backend if drag has ended (Drag is always false after a click)
   */
  const handleProgressBarUpdate = ((e: MouseEvent | React.MouseEvent<HTMLDivElement>) => {
    const { left, width } = progressRef.current.getBoundingClientRect()
    const clickX = e.clientX - left

    const progress = (clickX / width)
    const newTime = new Date(selectedSimulation?.initialTime).getTime() + progress * (new Date(endTime).getTime()
- new Date(selectedSimulation?.initialTime).getTime())
    const formattedTime = moment(newTime).format('HH:mm:ss')

    setProgressWidth(Math.max(0, Math.min(100, progress * 100)))

    if (!draggingRef.current) {
      webSocket?.current?.send(JSON.stringify({
        messageType: 'GOTO',
        to: formattedTime,
      }))
    }
  })

  const getCurrentTime = (progress: number) => {
    const date = moment(new Date(selectedSimulation?.initialTime).getTime() + (progress / 100)
  * (new Date(endTime).getTime()
- new Date(selectedSimulation?.initialTime).getTime()))

    return date.isValid() ? date.format('HH:mm:ss') : ''
  }
  const handleMouseUp = () => { draggingRef.current = false }
  const handleMouseDown = () => { draggingRef.current = true }
  const handleMouseMove = (e: MouseEvent) => draggingRef.current && handleProgressBarUpdate(e)

  useEffect(() => {
    document.addEventListener('mousemove', handleMouseMove)
    document.addEventListener('mouseup', handleMouseUp)

    return () => {
      document.removeEventListener('mouseup', handleMouseUp)
      document.removeEventListener('mousemove', handleMouseMove)
    }
  }, [])

  const [plannedProgress, setPlannedProgress] = useState(0)
  const [showTooltip, setTooltip] = useState(false)

  const onMonsed = event => {
    const tooltip = document.getElementById('tooltip')
    const container = document.getElementById('progress-container')

    const containerRect = container.getBoundingClientRect()
    const x = event.clientX - containerRect.left
    const { left, width } = progressRef.current.getBoundingClientRect()
    const clickX = event.clientX - left

    const progress = (clickX / width)
    setPlannedProgress(progress * 100)

    tooltip.style.left = `${x + 15}px`
  }

  return (
    <div className="time-tools">
      <div className="tools">
        <div className="actions">
          <div className="update-time-button" onClick={() => handleSendTimestamp(-15)}>
            <BackwardIcon />
            <span>15</span>
          </div>
          <div className="update-time-button" onClick={() => handleSendTimestamp(15)}>
            <ForwardIcon />
            <span>15</span>
          </div>
        </div>
      </div>
      <div
        id="tooltip"
        style={{ visibility: showTooltip ? 'visible' : 'hidden' }}
      >
        {getCurrentTime(plannedProgress)}
      </div>
      <div id="progress-container" className="progress-container">

        <div
          ref={progressRef}
          className="progress"
          onMouseEnter={() => setTooltip(true)}
          onMouseMove={onMonsed}
          onMouseLeave={() => setTooltip(false)}
          onClick={handleProgressBarUpdate}
        >

          <div
            className="progress-bar"
            style={{
              width: `${progressWidth}%`,
            }}
          >

            <div className="thumb" onMouseDown={handleMouseDown} />
          </div>

        </div>

        <div className="time-indicators">
          <span>{moment(selectedSimulation?.initialTime).format('HH:mm:ss')}</span>
          <span>{moment(endTime).format('HH:mm:ss')}</span>
        </div>
      </div>
    </div>
  )
}
