import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Simulation } from 'reducers/types'

export type Dashboard = {code: string, id: string, name: string, scheduleId: string, userId: string,
  simulations: (Simulation & {viewerStatus: string})[], forcePause: boolean;}

  type Student = {name: string, simulationId: string, timestamp: string; start: boolean;
    pause: boolean, viewerStatus: string}
export type TrainerState = {
  dashboard: Dashboard;
  simulation: Simulation;
  students: Student[];
}

const initialState: TrainerState = {
  dashboard: undefined,
  simulation: undefined,
  students: [],
}

const trainerSlice = createSlice({
  name: 'trainer',
  initialState,
  reducers: {
    setDashboard: (state, action: PayloadAction<Dashboard>) => {
      state.dashboard = action.payload
    },
    setSimulation: (state, action: PayloadAction<Simulation>) => {
      state.simulation = action.payload
    },
    stopSimulation: (state, action: PayloadAction<string>) => {
      const newDashboard = {
        ...state.dashboard,
        simulations: state.dashboard.simulations.map(sim => (sim.id === action.payload
          ? { ...sim, viewerStatus: 'STOPPED' } : sim)),
      }
      state.dashboard = newDashboard
    },
    updateStudents: (state, action: PayloadAction<{simulationId: string} & unknown>) => {
      const index = state.students.findIndex(st => st.simulationId === action.payload.simulationId)
      if (index !== -1) {
        state.students = state.students.map(st => (st.simulationId
          === action.payload.simulationId ? { ...st, ...action.payload } : st))
      } else {
        state.students.push(action.payload as any)
      }
    },
    setPause: (state, action: PayloadAction<boolean>) => {
      state.dashboard = { ...state.dashboard, forcePause: action.payload }
    },
  },
})

export const {
  setDashboard,
  setSimulation,
  updateStudents,
  stopSimulation,
  setPause,
} = trainerSlice.actions

export default trainerSlice.reducer
