import { useEffect, useRef, useState } from 'react'
import { LongBeep, ShortBeep } from 'reducers/types'
import { isLongBeep } from 'services/snci'

type Props = {
  beep: ShortBeep | LongBeep;
  canPlay: boolean;
}

export default function AudioPlayer({ beep, canPlay }: Props) {
  const audioContextRef = useRef(null)
  const oscillatorRef = useRef(null)

  const playSound = () => {
    if (!audioContextRef.current) {
      audioContextRef.current = new window.AudioContext()
    }
    const audioContext = audioContextRef.current
    const oscillator = audioContext.createOscillator()

    if (isLongBeep(beep)) {
      const { waveType, frequency, state } = beep

      if (!state) {
        if (oscillatorRef.current) {
          oscillatorRef.current.stop()
        }
        return
      }

      oscillatorRef.current = oscillator
      oscillatorRef.current.type = waveType.toLowerCase()
      oscillatorRef.current.frequency.setValueAtTime(frequency, audioContext.currentTime)
      oscillatorRef.current.connect(audioContext.destination)
      oscillatorRef.current.start()
    } else {
      const { waveType, frequency, duration } = beep
      oscillator.type = waveType.toLowerCase()
      oscillator.frequency.setValueAtTime(frequency, audioContext.currentTime)
      oscillator.connect(audioContext.destination)
      oscillator.start()
      oscillator.stop(audioContext.currentTime + duration)
    }
  }

  useEffect(() => {
    if (beep && canPlay) {
      playSound()
    }
  }, [beep, canPlay])

  return (null)
}
