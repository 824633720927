import PlayArrowIcon from '@mui/icons-material/PlayArrow'
import arrowRight from 'assets/icons/arrowRight.svg'
import { terms } from 'assets/localize/terms'
import SimpleButton, { ButtonStyle } from 'components/button'
import Header from 'components/header'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  resetSimulation,
  setNetworks, setSchedules,
  setSelectedVersion, toggleDisplayComments,
} from 'reducers/simulations/slices'
import {
  getCommentSeverityList,
  getCommentStatusList,
  getNetworks, getSchedules,
  getVersions,
  postSimulation,
} from 'reducers/simulations/thunks'
import { isDevEnv, useAppDispatch, useAppSelector } from 'utils'

import { PayloadAction } from '@reduxjs/toolkit'
import { createDashboard } from 'reducers/trainer/thunks'
import { Simulation } from 'reducers/types'
import Column from './column'
import './style.scss'

export default function Home() {
  const navigate = useNavigate()

  const {
    versions, networks, schedules, selectedSimulation,
  } = useAppSelector(state => state.simulations)
  const [versionId, setVersionId] = useState(null)
  const [networkId, setNetworkId] = useState(null)
  const [scheduleId, setScheduleId] = useState(null)
  const dispatch = useAppDispatch()

  useEffect(() => {
    setNetworkId(null)
    setScheduleId(null)
    dispatch(setNetworks([]))
    dispatch(setSchedules([]))
    dispatch(getVersions())
    dispatch(getCommentStatusList())
    dispatch(getCommentSeverityList())
    dispatch(resetSimulation())
  }, [])

  const handleVersionChange = (id: string) => {
    if (id !== versionId) {
      setNetworkId(null)
      setScheduleId(null)
      dispatch(setNetworks([]))
      dispatch(setSchedules([]))
    }
    setVersionId(id)
    dispatch(setSelectedVersion(id))
    dispatch(getNetworks(id))
  }

  const handleNetworkChange = (id: string) => {
    dispatch(getSchedules(id))
    setScheduleId(null)
    setNetworkId(id)
  }

  const handleScheduleChange = (id: string) => {
    setScheduleId(id)
  }

  const handleCreateSimulation = () => {
    if (window.location.pathname.includes('formateur') && isDevEnv()) {
      dispatch(createDashboard(scheduleId)).unwrap().then((response: any) => {
        navigate(`/dashboard/${response.payload.id}`)
      })
        .catch(() => undefined)

      return
    }
    dispatch(resetSimulation())
    dispatch(postSimulation(
      scheduleId,
      moment().format('YYMMDD-HHmm'),
    )).then(response => {
      if (response.type.endsWith('fulfilled')) {
        // todo fix dupe
        navigate(`/logs/${(response.payload as PayloadAction<Simulation>).payload?.id || ''}`, { replace: true })
      }
    })
    navigate(`/logs/${selectedSimulation?.id || ''}`)

    dispatch(toggleDisplayComments(false))
  }

  return (
    <div className="home">
      <Header />

      <div
        className="d-flex"
        style={{
          padding: '36px 56px', height: 'calc(100% - 90px)', gap: 20, flexWrap: 'nowrap', overflow: 'auto',
        }}
      >
        <Column
          list={versions}
          objectId={versionId}
          onChange={handleVersionChange}
          title={terms.Home.columns.titleVersion}
        />
        {versionId && (
        <>
          <div className="d-flex flex-column" style={{ marginTop: 65 }}>
            <img src={arrowRight} alt="" />
          </div>
          <Column
            list={networks}
            objectId={networkId}
            onChange={handleNetworkChange}
            title={terms.Home.columns.titleNetwork}
          />
        </>
        )}
        {networkId && (
          <>
            <div className="d-flex flex-column" style={{ marginTop: 65 }}>
              <img src={arrowRight} alt="" />
            </div>
            <Column
              list={schedules}
              objectId={scheduleId}
              onChange={handleScheduleChange}
              title={terms.Home.columns.titleSchedule}
            />
          </>
        )}
        {scheduleId && (
          <>
            <div className="d-flex flex-column" style={{ marginTop: 65 }}>
              <img src={arrowRight} alt="" />
            </div>
            <div className="d-flex flex-column" style={{ marginTop: 70 }}>
              <SimpleButton
                text={terms.Home.btn.openSimulation}
                style={ButtonStyle.primary}
                startIcon={<PlayArrowIcon />}
                sx={{ maxWidth: 300, alignSelf: 'center' }}
                onClick={() => handleCreateSimulation()}
              />
            </div>
          </>
        )}
      </div>
    </div>
  )
}
