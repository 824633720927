import terms from 'assets/localize/terms'
import { FilterOperators, FilterWithId } from 'types/filters'

export const getOperators = (column: string): FilterOperators[] => {
  switch (column) {
    case 'createdAt':
      return [FilterOperators.between, FilterOperators.gte, FilterOperators.lte]
    case 'severity':
    case 'status':
      return [FilterOperators.in, FilterOperators.notin]

    case 'userId':
      return [FilterOperators.in, FilterOperators.notin]

    case 'name':
      return [FilterOperators.contains, FilterOperators.notContains]

    case 'versionName':
      return [FilterOperators.contains, FilterOperators.notContains]

    default:
      return [FilterOperators.equals,
        FilterOperators.contains, FilterOperators.notContains,
        FilterOperators.notEquals, FilterOperators.notNull, FilterOperators.null]
  }
}

export const fields = ['userId', 'name', 'status', 'severity', 'createdAt', 'versionName']

export const HISTORY_COLUMNS = fields.map(el => ({ label: terms.Filter.columns[el], value: el }))

export const sanitizeFilters = (filters: FilterWithId[]) => {
  const newFilters = filters.flatMap(filt => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { id, ...rest } = filt

    if (filt.operator === FilterOperators.between) {
      const gteFilter = { ...rest, operator: FilterOperators.gte, value: filt.value[0] }
      const lteFilter = { ...rest, operator: FilterOperators.lte, value: filt.value[1] }

      return [gteFilter, lteFilter]
    }

    if (filt.operator === FilterOperators.null) {
      return { ...rest, value: true }
    }

    if (filt.operator === FilterOperators.notNull) {
      return { ...rest, operator: FilterOperators.null, value: false }
    }

    return { ...rest }
  })

  return newFilters
}

export const containsText = (text: string, searchText: string) => text
  .toLowerCase().indexOf(searchText.toLowerCase()) > -1
