import SimpleButton, { ButtonStyle } from 'components/button'
import { ReactElement } from 'react'
import { useNavigate } from 'react-router-dom'
import './style.scss'

export default function RoleSelection(): ReactElement {
  const navigate = useNavigate()
  return (
    <div className="roles-selection d-flex w-100 h-100 flex-column">
      <SimpleButton
        style={ButtonStyle.primary}
        text="Logs"
        onClick={() => navigate('/admin')}
      />
      <SimpleButton
        style={ButtonStyle.primary}
        text="Formateur"
        onClick={() => navigate('/formateur')}
      />

      <SimpleButton
        style={ButtonStyle.primary}
        text="Élève"
        onClick={() => navigate('/eleve')}
      />
    </div>
  )
}
