import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Simulation } from 'reducers/types'

export type TrainerState = {
  simulation: Simulation; // not exactly a simulation
}

const initialState: TrainerState = {
  simulation: undefined,
}

const studentSlice = createSlice({
  name: 'student',
  initialState,
  reducers: {
    setSimulation: (state, action: PayloadAction<Simulation>) => {
      state.simulation = action.payload
    },
  },
})

export const {
  setSimulation,
} = studentSlice.actions

export default studentSlice.reducer
